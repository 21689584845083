import {
  GREY_200,
  GREY_600,
  ERROR_RED,
  ROBOTO,
  WARNING_DARK,
} from 'lib/constants';

export default {
  card: {
    border: `1px solid ${GREY_200}`,
    borderRadius: '6px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  primaryInfo: {
    fontSize: '24px',
    lineHeight: '133%',
  },
  secondaryInfo: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: GREY_600,
  },
  nilEquipment: {
    color: ERROR_RED,
    textTransform: 'uppercase',
  },
  onHoldWarning: {
    alignItems: 'center',
    color: WARNING_DARK,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: ROBOTO,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '266%',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
};
