import { makeApiRequest } from 'lib/api';
import {
  TRUCK_POST,
  TRUCK_PUT,
  TRUCK_DRAWER_TOGGLE,
  SELECT_TRUCK,
  PRESELECT_ORDER_FOR_TRUCK,
  CLEAR_PRESELECTED_TRUCK_ORDER,
} from 'lib/constants';
import { addTruck } from 'redux/netsuiteOrderItem/actions';

import Trucks from './services';

export const createTruck = (params) => async (dispatch, getState) => {
  const response = await dispatch(
    makeApiRequest({
      type: TRUCK_POST,
      method: Trucks.createTruck,
      params: {
        packingFacilityId: getState().session.packing_facility_id,
        truck: params.truck,
      },
    })
  );

  if (response.ok) {
    dispatch(addTruck(response.truck));
  } else {
    //error handling
  }
};

export const updateTruck = (params) => async (dispatch, getState) => {
  await dispatch(
    makeApiRequest({
      type: TRUCK_PUT,
      method: Trucks.updateTruck,
      params: {
        packingFacilityId: getState().session.packing_facility_id,
        truck: params.truck,
        truckId: params.id,
      },
    })
  );
};

export const addOrderToTruck = ({ truck, netsuiteOrderItem }) => async (
  dispatch,
  getState
) => {
  const truckOrders = truck.netsuiteOrders.map((order) => ({
    netsuite_order_id: order.id,
    id: order.truckNetsuiteOrderId,
  }));
  truckOrders.push({
    truck_id: truck.id,
    netsuite_order_id: netsuiteOrderItem.netsuiteOrderId,
  });

  await dispatch(
    makeApiRequest({
      type: TRUCK_PUT,
      method: Trucks.updateTruck,
      params: {
        packingFacilityId: getState().session.packing_facility_id,
        truck: {
          truck_netsuite_orders_attributes: truckOrders,
        },
        truckId: truck.id,
      },
    })
  );
};

export const toggleTruckDrawer = (value) => ({
  type: TRUCK_DRAWER_TOGGLE,
  toggleDrawer: value,
});

export const selectTruck = (truck) => ({
  type: SELECT_TRUCK,
  selectedTruck: truck,
});

export const preselectOrderForTruck = (order) => ({
  type: PRESELECT_ORDER_FOR_TRUCK,
  order,
});

export const clearPreselectedTruckOrder = () => ({
  type: CLEAR_PRESELECTED_TRUCK_ORDER,
});
