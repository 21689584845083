// inspired by https://codepen.io/jh3y/pen/WyNdMG

import React from 'react';
import PropTypes from 'prop-types';

const RainSvg = ({ className }) => {
  return (
    <svg
      xmls="http://www.w3.org/2000/svg"
      viewBox="0 0 5 50"
      className={className}
    >
      <path
        fill="#a1c6cc"
        opacity={Math.random()}
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
  );
};

RainSvg.propTypes = {
  className: PropTypes.string,
};

RainSvg.defaultProps = {
  className: '',
};

export default RainSvg;
