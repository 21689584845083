import theme from 'lib/theme';

export default {
  root: {
    fontFamily: 'Muli',
    minHeight: '100%',
    margin: '0px auto',
    minWidth: '785px',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  toggleAllButtonGroup: {
    float: 'right',
  },
  controls: {
    height: '60px',
  },
  lastUpdated: {
    textAlign: 'right',
    marginRight: '24px',
    marginTop: '12px',
    fontSize: '0.87em',
  },
  flexGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  colSevenths: {
    width: '13.3%',
  },
  colSixths: {
    width: '16.67%',
  },
  colFifths: {
    width: '20%',
  },
  colFourths: {
    width: '25%',
  },
  containerMargins: {
    minHeight: '100%',
  },
  midwayRetailSpacing: {
    marginRight: '50px',
  },
  atlRetailSpacing: {
    marginTop: '60px',
  },
};
