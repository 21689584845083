import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import classNames from 'classnames';

import { Loading } from 'components/shared';
import { BACK_BUTTON, TEXT } from 'lib/constants';

import IceTable from './IceTable';

const ShipmentDetails = ({
  classes,
  onSetTopBarContent,
  onGetShipment,
  onClearShipmentDetails,
  viewBoxDetails,
  fetching,
  failed,
  trackingNumberFromUrl,
}) => {
  const shipment = useCallback(() => {
    return viewBoxDetails.shipmentId
      ? `ID ${viewBoxDetails.shipmentId}`
      : 'Box-Ray';
  }, [viewBoxDetails]);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: TEXT,
      text: shipment(),
      backLocation: '/box-ray/view-box-details',
    });
  }, [onSetTopBarContent, shipment, viewBoxDetails.shipmentId]);

  useEffect(() => {
    onClearShipmentDetails();
  }, [onClearShipmentDetails, trackingNumberFromUrl]);

  useEffect(() => {
    if (trackingNumberFromUrl) {
      onGetShipment({
        trackingNumber: trackingNumberFromUrl,
      });
    }
  }, [onGetShipment, trackingNumberFromUrl]);

  if (fetching) {
    return (
      <div className={classes.centeredLoadingContainer}>
        <Loading />
      </div>
    );
  } else if (failed) {
    return (
      <div className={classes.centeredLoadingContainer}>
        <Loading />
        <p className={classes.loadingMessage}>
          Something went wrong. Please reload the page, and try again.
        </p>
      </div>
    );
  } else if (viewBoxDetails.shipmentId) {
    return (
      <div className={classes.container}>
        {viewBoxDetails.flagged ? (
          <div className={classes.yellowCallout}>MATCH</div>
        ) : (
          <div className={classes.blueCallout}>NO MATCH</div>
        )}
        <List
          disablePadding
          className={classNames(classes.list, classes.zebra)}
        >
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Tracking Number
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.trackingNumber}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Brand
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.brand}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Pack Time
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.scanTime
                ? `Line ${viewBoxDetails.boxingLine} at ${viewBoxDetails.scanTime}`
                : 'Not Packed'}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Shipping Region
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.shippingRegion}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Shipping Region Category
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.shippingRegionCategory}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Customer Name
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.customerName} - ({viewBoxDetails.userId})
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Shipment Date
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.shipDate}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Delivery Date
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.deliveryDate}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Box Size
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.boxSize}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Temperature Zone
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.temperatureZone}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Shipment Id
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.shipmentId}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Pick Ticket Id
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.pickTicketId}
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Basket Id
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {viewBoxDetails.basketId}
            </ListItemText>
          </ListItem>
        </List>
        <List disablePadding>
          <IceTable icePacks={viewBoxDetails.icePacks} classes={classes} />
        </List>
        <List disablePadding>
          <ListSubheader className={classes.subHeader}>
            <ListItemText
              className={classNames(classes.listItemHeader, classes.whiteText)}
              disableTypography
            >
              PROTEIN
            </ListItemText>
            <ListItemText
              className={classNames(
                classes.listItemQuantity,
                classes.whiteText
              )}
              disableTypography
            >
              QTY
            </ListItemText>
          </ListSubheader>
        </List>
        <List
          disablePadding
          className={classNames(classes.list, classes.zebra)}
        >
          {viewBoxDetails.proteins.map((protein) => {
            return (
              <ListItem key={`${protein.pick_station}-${protein.picks}`}>
                <ListItemText className={classes.pickStation} disableTypography>
                  {protein.pick_station}
                </ListItemText>
                <ListItemText disableTypography>{protein.name}</ListItemText>
                <ListItemText
                  className={classes.listItemQuantity}
                  disableTypography
                >
                  {protein.picks}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
        <List disablePadding>
          <ListSubheader className={classes.subHeader}>
            <ListItemText
              className={classNames(classes.listItemHeader, classes.whiteText)}
              disableTypography
            >
              MEAL
            </ListItemText>
            <ListItemText
              className={classNames(
                classes.listItemQuantity,
                classes.whiteText
              )}
              disableTypography
            >
              QTY
            </ListItemText>
          </ListSubheader>
        </List>
        <List
          disablePadding
          className={classNames(classes.list, classes.zebra)}
        >
          {viewBoxDetails.meals.map((meal) => {
            return (
              <ListItem key={`${meal.id}-${meal.name}`}>
                <ListItemText className={classes.pickStation} disableTypography>
                  {meal.pick_station}
                </ListItemText>
                <ListItemText disableTypography>{meal.name}</ListItemText>
                <ListItemText
                  className={classes.listItemQuantity}
                  disableTypography
                >
                  {meal.picks}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  } else {
    return (
      <div className={classes.errorMessage}>Sorry, no shipment was found.</div>
    );
  }
};

ShipmentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  onGetShipment: PropTypes.func.isRequired,
  viewBoxDetails: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  trackingNumberFromUrl: PropTypes.string.isRequired,
};

export default ShipmentDetails;
