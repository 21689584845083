import {
  GET_ACTIVITY_INGREDIENTS,
  GET_MEAL_PROGRESSES,
  GET_ACTIVITY_INGREDIENT_PROGRESSES,
  SUBMIT_MEAL_PROGRESS,
  SUBMIT_INGREDIENT_PROGRESS,
  PRINT_RTH_LABELS,
  UPDATE_INGREDIENT_PROGRESS,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import {
  getMealProgresses,
  getActivityIngredients,
  getActivityIngredientProgresses,
  postMealProgress,
  putMealProgress,
  postIngredientProgress,
  putIngredientProgress,
  printRthLabels,
} from './services';

export const fetchActivityIngredients = (params) => {
  return makeApiRequest({
    type: GET_ACTIVITY_INGREDIENTS,
    method: getActivityIngredients,
    params,
  });
};

export const fetchActivityIngredientProgresses = ({
  activity,
  ingredientId,
  mealIds,
}) => {
  return makeApiRequest({
    type: GET_ACTIVITY_INGREDIENT_PROGRESSES,
    method: getActivityIngredientProgresses,
    params: { activity, ingredientId, mealIds },
  });
};

export const fetchMealProgresses = (params) => {
  return makeApiRequest({
    type: GET_MEAL_PROGRESSES,
    method: getMealProgresses,
    params,
  });
};

export const submitMealProgress = (params) => {
  return makeApiRequest({
    type: SUBMIT_MEAL_PROGRESS,
    method: postMealProgress,
    params,
  });
};

export const updateMealProgress = (params) => {
  return makeApiRequest({
    type: SUBMIT_MEAL_PROGRESS,
    method: putMealProgress,
    params,
  });
};

export const submitIngredientProgress = (params) => {
  return makeApiRequest({
    type: SUBMIT_INGREDIENT_PROGRESS,
    method: postIngredientProgress,
    params,
  });
};

export const updateIngredientProgress = (params) => {
  return makeApiRequest({
    type: UPDATE_INGREDIENT_PROGRESS,
    method: putIngredientProgress,
    params,
  });
};

export const printLabels = (params) => {
  return makeApiRequest({
    type: PRINT_RTH_LABELS,
    method: printRthLabels,
    params,
  });
};
