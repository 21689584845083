import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Route as ReactRouterDOMRoute, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@mui/styles/makeStyles';

import {
  ASSEMBLY,
  ACCOUNT_DROPDOWN,
  COMPOUND_INGREDIENTS,
  MEALS,
  HOME_BUTTON,
  OVERVIEW,
  RTH_PROGRESS_TRACKER_HOME,
  SLACKING,
  TEXT,
} from 'lib/constants';
import { setTopBarContent } from 'redux/topBar/actions';
import { fetchMenusAndSelectFirst } from 'redux/menu/actions';
import { Loading } from 'components/shared';

import ProgressNavigation from './ProgressNavigation';
import Overview from './Overview';
import Slacking from './Slacking';
import CompoundIngredients from './CompoundIngredients';
import Assembly from './Assembly';
import * as styles from './styles';

const useStyles = makeStyles(styles);

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const RthProgressTracker = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTopBarContent({
        leftContent: HOME_BUTTON,
        middleContent: TEXT,
        text: 'PROGRESS TRACKER',
        rightContent: ACCOUNT_DROPDOWN,
      })
    );
  }, []);

  const { results: menus, selectedMenu } = useSelector((state) => state.menus);

  useEffect(() => {
    isEmpty(menus) && dispatch(fetchMenusAndSelectFirst(true));
  }, []);

  return !selectedMenu.id ? (
    <div className={classes.loadingSpinner}>
      <Loading />
    </div>
  ) : (
    <>
      <Route
        path={[RTH_PROGRESS_TRACKER_HOME]}
        render={() => <ProgressNavigation />}
      />
      <Route
        path={[`${RTH_PROGRESS_TRACKER_HOME}/${OVERVIEW}`]}
        render={() => <Overview />}
      />
      <Route exact path={[`${RTH_PROGRESS_TRACKER_HOME}/${SLACKING}`]}>
        <Redirect
          to={`${RTH_PROGRESS_TRACKER_HOME}/${SLACKING}/${selectedMenu.id}`}
        />
      </Route>
      <Route
        path={[`${RTH_PROGRESS_TRACKER_HOME}/${SLACKING}/:menuId`]}
        render={() => <Slacking />}
      />
      <Route
        exact
        path={[`${RTH_PROGRESS_TRACKER_HOME}/${COMPOUND_INGREDIENTS}`]}
      >
        <Redirect
          to={`${RTH_PROGRESS_TRACKER_HOME}/${COMPOUND_INGREDIENTS}/${selectedMenu.id}`}
        />
      </Route>
      <Route
        path={[`${RTH_PROGRESS_TRACKER_HOME}/${COMPOUND_INGREDIENTS}/:menuId`]}
        render={() => <CompoundIngredients />}
      />
      <Route
        path={[`${RTH_PROGRESS_TRACKER_HOME}/${MEALS}/:menuId`]}
        render={() => <CompoundIngredients />}
      />
      <Route exact path={[`${RTH_PROGRESS_TRACKER_HOME}/${ASSEMBLY}`]}>
        <Redirect
          to={`${RTH_PROGRESS_TRACKER_HOME}/${ASSEMBLY}/${selectedMenu.id}`}
        />
      </Route>
      <Route
        path={[`${RTH_PROGRESS_TRACKER_HOME}/${ASSEMBLY}/:menuId`]}
        render={() => <Assembly />}
      />
    </>
  );
};

export default RthProgressTracker;
