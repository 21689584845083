import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import classNames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AddIcon from '@mui/icons-material/Add';
import ReactResizeDetector from 'react-resize-detector';

import { useDebounce } from 'lib/custom_hooks';
import { TabOutlineDiv, TabOutlineButton } from 'components/shared';
import { ENTER_KEY_CODE, PHONE_SCREEN } from 'lib/constants';

const userResultShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
  packingFacilityName: PropTypes.string.isRequired,
  packingFacilityNameAbbr: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
});

const SearchResults = ({
  classes,
  userSearchResults,
  searching,
  onSelectUser,
  phoneScreen,
  onKeyDown,
}) => {
  if (userSearchResults.length > 0) {
    return userSearchResults.map((user) => (
      <TabOutlineDiv
        onKeyDown={(e) => onKeyDown(e, user)}
        onClick={() => onSelectUser(user)}
        className={classes.result}
        key={user.id}
      >
        {user.name}
        <div className={classes.userDetails}>
          <span className={classes.detailItem}>ID: {user.id}</span>
          <span className={classes.detailItem}>
            Facility:{' '}
            {phoneScreen
              ? user.packingFacilityNameAbbr
              : user.packingFacilityName}
          </span>
          {!phoneScreen && (
            <span className={classes.detailItem}>Role: {user.role}</span>
          )}
        </div>
      </TabOutlineDiv>
    ));
  }
  if (searching) {
    return <div className={classes.noClickResult}>Searching...</div>;
  }
  return <div className={classes.noClickResult}>No Users Found</div>;
};

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  userSearchResults: PropTypes.arrayOf(userResultShape).isRequired,
  searching: PropTypes.bool.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  phoneScreen: PropTypes.bool.isRequired,
};

const UserSearch = ({
  classes,
  onCreateUser,
  onSearchUsers,
  userSearchResults,
  nameSelected,
  searching,
  onSelectUser,
  onClearUser,
  onClearResults,
  canCreate,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const [open, setOpen] = useState(false);
  const [searchMode, setSearchMode] = useState(true);
  const inputRef = useRef(null);

  const [name, setName] = useState('');
  const nameIsValid = name.match(/(\w.+\s).+/i);

  const delayedUserSearch = useDebounce((search) => onSearchUsers(search));

  const isAValidNumber = useMemo(() => !isNaN(name) && name.length > 0, [name]);

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (event.target.value.length > 2 || isAValidNumber) {
      delayedUserSearch(event.target.value);
    }
  };

  useEffect(() => {
    if (searchMode) {
      inputRef.current.focus();
    }
  }, [searchMode]);

  useEffect(() => {
    if ((name.length > 2 || isAValidNumber) && searchMode) {
      setOpen(true);
    } else {
      setOpen(false);
      onClearResults();
    }
  }, [name, searchMode, onClearResults, isAValidNumber]);

  useEffect(() => {
    if (nameSelected && searchMode) {
      setSearchMode(false);
    } else if (!nameSelected && !searchMode) {
      setSearchMode(true);
    }
  }, [nameSelected, searchMode]);

  const handleCreateOrSelect = (selectedUser = null) => {
    if (!selectedUser && canCreate) {
      if (!nameIsValid) {
        return;
      }
      onCreateUser(name);
    } else {
      onSelectUser(selectedUser);
    }
    setSearchMode(false);
    setName('');
    onClearResults();
  };

  const handleClear = () => {
    onClearUser();
    setSearchMode(true);
  };

  const handleClickAway = () => {
    setName('');
    setOpen(false);
  };

  const handleKeyPress = (e, selectedUser = null) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      handleCreateOrSelect(selectedUser);
    }
  };

  // popover width related stuff
  const [width, setWidth] = useState(null);
  useEffect(() => {
    if (open) {
      setWidth(inputRef.current.clientWidth);
    }
  }, [setWidth, open]);

  const onResize = () => {
    if (open) {
      setWidth(inputRef.current.clientWidth);
    }
  };

  const createText = `${!phoneScreen ? 'Create New User - ' : ''}${name}${
    nameIsValid ? '' : ' (Must Enter Full Name)'
  }`;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div data-testid="user-search">
        <div>
          <ReactResizeDetector handleWidth onResize={onResize} />

          {searchMode ? (
            <TextField
              label="First and Last Name"
              onChange={handleNameChange}
              variant="outlined"
              className={classes.input}
              type="search"
              autoComplete="off"
              value={name}
              inputRef={inputRef}
              inputProps={{ name: 'homechef_ops_login', maxLength: 100 }}
            />
          ) : (
            <div
              className={classNames(
                classes.nameContainer,
                classes.staticNameContainer
              )}
            >
              <div className={classes.staticName}>{nameSelected}</div>
              <TabOutlineButton
                type="button"
                onClick={handleClear}
                className={classes.clearButton}
              >
                Clear
              </TabOutlineButton>
            </div>
          )}
        </div>
        <Popper
          open={open}
          anchorEl={inputRef.current}
          className={classes.popper}
          placement="bottom-start"
          disablePortal
        >
          <div style={{ width }} className={classes.popoverContent}>
            <div className={classes.resultsContainer}>
              <SearchResults
                searching={searching}
                userSearchResults={userSearchResults}
                classes={classes}
                onSelectUser={handleCreateOrSelect}
                phoneScreen={phoneScreen}
                onKeyDown={handleKeyPress}
              />
            </div>
            {canCreate && (
              <TabOutlineDiv
                className={classNames(classes.createNew, {
                  [classes.disabledCreate]: !nameIsValid,
                })}
                onClick={() => handleCreateOrSelect()}
                onKeyDown={handleKeyPress}
              >
                <AddIcon className={classes.addIcon} />
                {createText}
              </TabOutlineDiv>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

UserSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  userSearchResults: PropTypes.arrayOf(userResultShape).isRequired,
  searching: PropTypes.bool.isRequired,
  nameSelected: PropTypes.string,
  onCreateUser: PropTypes.func,
  onSearchUsers: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onClearUser: PropTypes.func.isRequired,
  onClearResults: PropTypes.func.isRequired,
  canCreate: PropTypes.bool,
};

UserSearch.defaultProps = {
  nameSelected: '',
  canCreate: true,
  onCreateUser: () => {},
};

export default UserSearch;
