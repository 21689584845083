import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

import Spring from 'components/shared/Spring/Spring';
import {
  CHICAGO_FACILITY_ID,
  ATLANTA_FACILITY_ID,
  SB_FACILITY_ID,
} from 'lib/constants';

import AppLinkCard from './AppLinkCard';
import styles from './styles';

const GRID_SPACING = 2;

const AppsLandingPage = ({
  classes,
  onResetTopBarToDefault,
  packingFacility,
  rthAppsEnabled,
}) => {
  useEffect(() => {
    onResetTopBarToDefault();
  }, [onResetTopBarToDefault]);

  const renderRthApps = () => {
    if (rthAppsEnabled) {
      return (
        <>
          <Grid item>
            <AppLinkCard
              label="RTH Production Submissions"
              icon="rthSubmissions"
              link="/rth-production-submissions/"
            />
          </Grid>
          <Grid item>
            <AppLinkCard
              label="RTH Progress Tracker"
              icon="rthProgress"
              link="/rth-progress-tracker/"
            />
          </Grid>
        </>
      );
    }
  };

  const renderPersonnelTrackingForCurrentFacility = () => {
    switch (packingFacility) {
      case CHICAGO_FACILITY_ID:
        return (
          <Grid item>
            <AppLinkCard
              label="Personnel Tracking"
              icon="personnelTracking"
              link="https://script.google.com/a/homechef.com/macros/s/AKfycbzPHyfB3E-he9G7kdAIkKDwPh1RHJsFbka2gqLZo7ltq4lLKGRr/exec?facility=CHI"
              isExternal
              isNewTab
            />
          </Grid>
        );
      case SB_FACILITY_ID:
        return (
          <Grid item>
            <AppLinkCard
              label="Personnel Tracking"
              icon="personnelTracking"
              link="https://script.google.com/a/homechef.com/macros/s/AKfycbzPHyfB3E-he9G7kdAIkKDwPh1RHJsFbka2gqLZo7ltq4lLKGRr/exec?facility=SB"
              isExternal
              isNewTab
            />
          </Grid>
        );
      case ATLANTA_FACILITY_ID:
        return (
          <Grid item>
            <AppLinkCard
              label="Personnel Tracking"
              icon="personnelTracking"
              link="https://script.google.com/a/homechef.com/macros/s/AKfycbzPHyfB3E-he9G7kdAIkKDwPh1RHJsFbka2gqLZo7ltq4lLKGRr/exec?facility=ATL"
              isExternal
              isNewTab
            />
          </Grid>
        );
    }
  };

  return (
    <div className={classes.wrapper}>
      <Spring />
      <Grid justifyContent="center" container className={classes.root}>
        <Grid item className={classes.grid}>
          <h3 className={classNames(classes.heading, classes.firstHeading)}>
            Production Apps
          </h3>

          <Grid container spacing={GRID_SPACING}>
            <Grid item>
              <AppLinkCard
                label="Production Inventory"
                icon="productionInventory"
                link="/production_inventory/meal_bagging/menu"
              />
            </Grid>

            <Grid item>
              <AppLinkCard
                label="Warehouse Requests"
                icon="warehouseRequests"
                link="/warehouse_requests/requests/menu"
              />
            </Grid>

            <Grid item>
              <AppLinkCard label="Box Ray" icon="boxRayIcon" link="/box-ray/" />
            </Grid>

            <Grid item>
              <AppLinkCard
                label="Production Sheets"
                icon="productionSheets"
                link="/production_sheets/menu"
                isWIP
              />
            </Grid>

            <Grid item>
              <AppLinkCard
                label="SWAT Spot"
                icon="swatSpot"
                link="https://sites.google.com/homechef.com/swat/?pli=1"
                isExternal
                isNewTab
              />
            </Grid>

            {renderRthApps()}
          </Grid>

          <h3 className={classes.heading}>QA Apps</h3>

          <Grid container spacing={GRID_SPACING}>
            <Grid item>
              <AppLinkCard
                label="QA Portioning"
                icon="portioning"
                link="/qa/portioning"
              />
            </Grid>

            <Grid item>
              <AppLinkCard
                label="QA Meal Bagging"
                icon="mealBagging"
                link="/qa/meal_bagging"
              />
            </Grid>

            {packingFacility === 1 && (
              <Grid item>
                <AppLinkCard
                  label="QA Personnel Tracking"
                  icon="qaPersonnelTracking"
                  link="https://docs.google.com/forms/d/e/1FAIpQLSdYWrzjxcSuhIVZ6zndLHFTLeAdJ573Fa9fKEqF6aP1GObq_w/viewform"
                  isExternal
                  isNewTab
                />
              </Grid>
            )}
          </Grid>

          <h3 className={classes.heading}>Performance & Planning Apps</h3>

          <Grid container spacing={GRID_SPACING}>
            <Grid item>
              <AppLinkCard
                label="Progress Tracker"
                icon="progress"
                link="progress_tracker/overview"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Production Submissions"
                icon="inventory"
                link="/production_submissions"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Real-Time Dashboard"
                icon="realtime"
                link="/realtime_dashboard"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Drop Zone & Pod Management"
                abbrvLabel="DZ & Pod Mgmt"
                icon="dropZoneAndPodManagement"
                link="/item-manager/dropzones"
              />
            </Grid>
            {renderPersonnelTrackingForCurrentFacility()}
          </Grid>

          <h3 className={classes.heading}>Warehouse Apps</h3>

          <Grid container spacing={GRID_SPACING}>
            <Grid item>
              <AppLinkCard
                label="Receiving"
                icon="receiving"
                link="/receiving/warehouse"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Inventory Manager"
                icon="warehouseInventory"
                link="/inventory-manager"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Dock Scheduler"
                icon="dockScheduler"
                link="/dock_scheduler"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Moves"
                icon="warehouseMoves"
                link="/warehouse_moves"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Cycle Counting"
                icon="cycleCounting"
                link="/cycle-counting"
              />
            </Grid>
            <Grid item>
              <AppLinkCard
                label="Truck Loading"
                icon="truckLoading"
                link="https://script.google.com/a/macros/homechef.com/s/AKfycbxdQ7azknjnmtbBDb9abiG1j-nXzqj1JVEmLpZ5XrgiSOn7nUr673I0rSloQtRmwD09Gg/exec"
                isExternal
                isNewTab
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

AppsLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onResetTopBarToDefault: PropTypes.func.isRequired,
  packingFacility: PropTypes.number.isRequired,
  rthAppsEnabled: PropTypes.bool,
};

AppsLandingPage.defaultProps = {
  rthAppsEnabled: false,
};

const mapStateToProps = (state) => ({
  packingFacility: state.session.packing_facility_id,
  rthAppsEnabled: state.session.experiments?.rth_progress,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppsLandingPage));
