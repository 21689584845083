import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import styles from './styles';

const useStyles = makeStyles(styles);

const IngredientList = ({ ingredients, headerText }) => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = (ingredient) => {
    const newLocationPath = `${history.location.pathname}/ingredients/${ingredient.id}`;
    const locationState = {
      breadCrumbText: `${ingredient.name} - ${ingredient.id}`,
    };
    history.push(newLocationPath, locationState);
  };

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>{headerText}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={classes.clickableRows}>
          {map(orderBy(ingredients, 'name', 'asc'), (ingredient) => {
            return (
              <TableRow
                key={ingredient.id}
                className={classes.zebraRow}
                onClick={() => onClick(ingredient)}
              >
                <TableCell className={classes.cell}>
                  {ingredient.name} - {ingredient.id}
                </TableCell>
                <TableCell className={classes.cellLast}>
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

IngredientList.propTypes = {
  ingredients: PropTypes.array.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default IngredientList;
