import React from 'react';

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';

// import { SLACKING, FIVE_MINUTE_INTERVAL } from 'lib/constants';

// import ExpandAndCollapsibleButtons from '../ExpandAndCollapsibleButtons';
// import MenuNavigation from '../MenuNavigation';
// import IngredientsForSlacking from '../IngredientsForSlacking';
// import styles from './styles';

// const useStyles = makeStyles(styles);

const Slacking = () => {
  // const classes = useStyles();
  // const [expandAll, setExpandAll] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  // const { menuId } = useParams();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     location.reload();
  //   }, FIVE_MINUTE_INTERVAL);
  //   return () => clearInterval(interval);
  // });

  return (
    <div>
      <h2>Slacking</h2>
      <p>Coming soon!</p>
    </div>
    // <>
    //   <MenuNavigation path={SLACKING} menusCount={5} />
    //   <div className={classes.container}>
    //     <div className={classes.selectControls}>
    //       <div />
    //       <ExpandAndCollapsibleButtons
    //         setExpandAll={setExpandAll}
    //         setTrigger={setTrigger}
    //         trigger={trigger}
    //       />
    //     </div>
    //     {menuId && (
    //       <IngredientsForSlacking expandAll={expandAll} trigger={trigger} />
    //     )}
    //   </div>
    // </>
  );
};

export default Slacking;
