import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { requestPodLocations } from 'redux/podLocation/actions';
import { requestPodStatuses } from 'redux/podStatus/actions';
import {
  unsubscribeToPodStatuses,
  subscribeToPodStatuses,
} from 'redux/podStatus/channels';

import RealtimeDashboard from './RealtimeDashboard';

export const RealtimeDashboardContainer = ({
  session,
  onRequestPodLocations,
  onRequestPodStatuses,
  onSubscribeToPodStatuses,
  podLocations,
  podStatuses,
}) => {
  const facilityId = session.packing_facility_id;
  useEffect(() => {
    onRequestPodLocations({ facilityId });
    onRequestPodStatuses({ facilityId });
    onSubscribeToPodStatuses(facilityId);

    return () => {
      unsubscribeToPodStatuses(facilityId);
    };
  }, [
    session,
    onRequestPodLocations,
    onRequestPodStatuses,
    onSubscribeToPodStatuses,
    facilityId,
  ]);

  return (
    <RealtimeDashboard
      session={session}
      podLocations={podLocations}
      podStatuses={podStatuses}
    />
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
  podLocations: state.podLocations,
  podStatuses: state.podStatuses,
});

const mapDispatchToProps = {
  onRequestPodLocations: requestPodLocations,
  onRequestPodStatuses: requestPodStatuses,
  onSubscribeToPodStatuses: subscribeToPodStatuses,
};

RealtimeDashboardContainer.propTypes = {
  podLocations: PropTypes.object.isRequired,
  session: PropTypes.shape({
    packing_facility_id: PropTypes.number.isRequired,
  }).isRequired,
  onRequestPodLocations: PropTypes.func.isRequired,
  onRequestPodStatuses: PropTypes.func.isRequired,
  onSubscribeToPodStatuses: PropTypes.func.isRequired,
  podStatuses: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealtimeDashboardContainer);
