import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';

import { SubtractionInputs, FormContainer } from 'components/shared';
import {
  APPLICATOR,
  ECOMMERCE_DB,
  RETAIL_DB,
  RETAIL,
  LABELING,
} from 'lib/constants';
import InventorySubmitButton from 'components/shared/InventorySubmitButton';
import { usePrevious } from 'lib/custom_hooks';

import IngredientDataCard from '../IngredientDataCard/IngredientDataCard';
import {
  ConfirmationDialog,
  LabelingMethodQuestion,
  TeamNumberQuestion,
} from '../formComponents';

const LabelInventoryForm = ({
  onPostLabelInventory,
  printers,
  posting,
  errors,
  history,
  location,
}) => {
  const [labelingCount, setLabelingCount] = useState(0);
  const [teamNumber, setTeamNumber] = useState(undefined);
  const [labelingMethod, setLabelingMethod] = useState(APPLICATOR);
  const [dialogOpen, setDialogOpen] = useState(false);

  const ingredientAssignment = location.state.ingredientAssignment;
  const formIsValid = isFinite(Number(labelingCount)) && labelingMethod;

  // Negated used, since old app normalized the input values
  // to only accept positive. We are allowing negative on the front-end now
  // Can potentially remove instances of negated after old application for
  // labeling is removed
  const submissionData = {
    count: labelingCount,
    negated: 1,
    packaged_by: labelingMethod,
    ingredient_id: ingredientAssignment.ingredientId,
    meal_id: ingredientAssignment.mealId,
    menu_id: ingredientAssignment.menuId,
    channel:
      ingredientAssignment.mealPlan === RETAIL ? RETAIL_DB : ECOMMERCE_DB,
    team_number: teamNumber,
  };

  const prevPosting = usePrevious(posting);

  useEffect(() => {
    if (!posting && prevPosting && !errors) {
      history.push('/production_inventory/labeling');
    }
  }, [history, prevPosting, posting, errors]);

  return (
    <FormContainer>
      <IngredientDataCard ingredientAssignment={ingredientAssignment} />
      <Card>
        <SubtractionInputs
          initialValue={ingredientAssignment.remainingCount}
          subtractingValue={labelingCount}
          updateSubtractingValue={(value) => setLabelingCount(value)}
          hasError={labelingCount < 0}
          autoFocus
          inputType="number"
          text={{
            initialValueLabel: 'Initial',
            subtractingValueLabel: '# Labels',
            resultLabel: 'Final',
          }}
        />
      </Card>
      <Card>
        <LabelingMethodQuestion
          labelingMethod={labelingMethod}
          setLabelingMethod={setLabelingMethod}
        />
      </Card>
      <Card>
        <TeamNumberQuestion
          teamNumber={teamNumber}
          setTeamNumber={setTeamNumber}
        />
      </Card>
      <InventorySubmitButton
        count={labelingCount}
        item="Label"
        disabled={!formIsValid}
        setDialogOpen={setDialogOpen}
      />
      <ConfirmationDialog
        open={dialogOpen}
        activity={LABELING}
        ingredientName={ingredientAssignment.name}
        packagingType={ingredientAssignment.packagingType}
        mealName={ingredientAssignment.mealName}
        handleClose={() => setDialogOpen(false)}
        submissionData={submissionData}
        printers={printers}
        posting={posting}
        onSubmit={onPostLabelInventory}
      />
    </FormContainer>
  );
};

LabelInventoryForm.propTypes = {
  onPostLabelInventory: PropTypes.func.isRequired,
  printers: PropTypes.array.isRequired,
  posting: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

LabelInventoryForm.defaultProps = {
  errors: null,
};

export default LabelInventoryForm;
