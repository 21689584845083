import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import ArrowIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import format from 'date-fns/format';
import { useDebounce } from 'lib/custom_hooks';
import { ENTER_KEY_CODE, WAREHOUSE_MOVES_CONTENT } from 'lib/constants';
import { ListItemLink } from 'components/shared';

import styles from './styles';

const PalletSearch = ({
  classes,
  onSearchItems,
  onClearResults,
  onStoreSearchQuery,
  palletFetchingQuery,
  pallets,
  queryTerm,
  drawerOpen,
  onToggleSearchDrawer,
  iconHeight,
  parent,
  itemMoveRequest,
}) => {
  const [didSearch, setDidSearch] = useState(false);

  const buildLink = (pallet) => {
    if (parent === WAREHOUSE_MOVES_CONTENT) {
      return `/warehouse_moves/pallet_search/${pallet.id}/manage/${itemMoveRequest.id}`;
    } else if (
      /inventory-manager/.test(window.location.href) ||
      /cycle-counting/.test(window.location.href)
    ) {
      return `/inventory-manager/pallets/${pallet.id}`;
    } else {
      return `/warehouse_moves/pallet_search/${pallet.id}`;
    }
  };

  const handleValidQueryTerm = () => {
    onSearchItems();
    setDidSearch(true);
  };

  const delayedHandleQuery = useDebounce(handleValidQueryTerm);

  const onInputKeydown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE && queryTerm.length) {
      delayedHandleQuery();
      e.target.blur();
    }
  };

  const onInputChange = (e) => {
    let query = e.target.value;
    onStoreSearchQuery(query);
    if (query.length >= 3) {
      delayedHandleQuery();
    } else {
      setDidSearch(false);
      onClearResults();
    }
  };

  const formatProductDate = (pallet) => {
    if (!pallet.productDate) return;
    const productDate = new Date(pallet.productDate);
    const productDateUTC = new Date(
      productDate.valueOf() + productDate.getTimezoneOffset() * 60 * 1000
    );
    return ` - ${format(productDateUTC, 'MM/dd/yy')}`;
  };

  return (
    <Fragment>
      <IconButton
        aria-label="Search"
        color="inherit"
        onClick={onToggleSearchDrawer}
        size="large"
      >
        <SearchIcon style={{ height: iconHeight, width: iconHeight }} />
      </IconButton>
      <Drawer
        open={drawerOpen}
        anchor="right"
        classes={{ paper: classes.fullWidth }}
      >
        <div className={classes.root}>
          <div className={classes.topSection}>
            <ArrowIcon
              id="goBack"
              fontSize="large"
              className={classes.arrow}
              onClick={onToggleSearchDrawer}
            />
            <TextField
              type="search"
              onChange={onInputChange}
              onKeyDown={onInputKeydown}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={queryTerm}
              autoComplete="off"
              placeholder="Search..."
              autoFocus
              inputProps={{ className: classes.input }}
            />
          </div>
          <div className={classes.bodySection}>
            <List className={classes.palletList} disablePadding>
              {pallets.length < 1 && didSearch && !palletFetchingQuery ? (
                <p className={classes.sorryContainer}>No Pallets Found</p>
              ) : (
                ''
              )}
              {pallets.map((pallet, index) => (
                <ListItemLink
                  grey={index % 2 === 0}
                  className={classes.listItemLink}
                  showArrow={false}
                  key={`pallet-${pallet.id}`}
                  link={buildLink(pallet)}
                  onClick={onToggleSearchDrawer}
                >
                  <ListItemText
                    className={classes.palletItem}
                    disableTypography
                    primary={
                      <div className={classes.topLine}>
                        <span className={classes.palletItemName}>
                          {pallet.internalItemName}
                        </span>
                        <span className={classes.palletId}>
                          {' '}
                          {`Lot ${pallet.id}`}
                        </span>
                      </div>
                    }
                    secondary={
                      <ListItem className={classes.palletInfoWrapper}>
                        <ListItemText
                          className={classes.palletLocation}
                          disableTypography
                        >
                          {pallet.location}
                          {formatProductDate(pallet)}
                        </ListItemText>
                        <ListItemText
                          className={classes.palletQuantity}
                          disableTypography
                        >
                          Qty: {pallet.quantity}
                        </ListItemText>
                      </ListItem>
                    }
                  />
                </ListItemLink>
              ))}
            </List>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

PalletSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  palletFetchingQuery: PropTypes.bool.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  onSearchItems: PropTypes.func.isRequired,
  onClearResults: PropTypes.func.isRequired,
  onStoreSearchQuery: PropTypes.func.isRequired,
  onToggleSearchDrawer: PropTypes.func.isRequired,
  queryTerm: PropTypes.string.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      location: PropTypes.string,
      internalItemName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      productDate: PropTypes.string,
    })
  ).isRequired,
  iconHeight: PropTypes.string,
  parent: PropTypes.string,
  itemMoveRequest: PropTypes.object,
};

PalletSearch.defaultProps = {
  iconHeight: '27px',
  parent: '',
  itemMoveRequest: {},
};

export default withStyles(styles)(PalletSearch);
