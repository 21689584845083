import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import { SLACKING, MIXING, COOKING, CHILLING, ASSEMBLY } from 'lib/constants';
import { Breadcrumbs } from 'components/shared';

import { BreadcrumbContext } from '../BreadcrumbProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const ActivityNavigation = ({ history }) => {
  const classes = useStyles();
  const breadcrumbs = useContext(BreadcrumbContext);

  if (breadcrumbs.length) {
    return (
      <div className={classes.breadcrumbContainer}>
        <Breadcrumbs backButton context={BreadcrumbContext} history={history} />
      </div>
    );
  }

  return (
    <>
      <div className={classes.linkContainer}>
        <NavLink
          to={`/rth-production-submissions/${SLACKING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {SLACKING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${MIXING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {MIXING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${COOKING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {COOKING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${CHILLING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {CHILLING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/rth-production-submissions/${ASSEMBLY}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {ASSEMBLY.toUpperCase()}
        </NavLink>
      </div>
    </>
  );
};

export default ActivityNavigation;
