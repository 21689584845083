import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import classNames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';

import {
  Loading,
  ListItemLink,
  PalletScanTextField,
  Snackbar,
} from 'components/shared';
import {
  BACK_BUTTON,
  INVENTORY_MANAGER_ACTIONS,
  QR_CODE_WITH_TEXT,
  LARGER_THAN_PHONE,
} from 'lib/constants';
import { formatDateAsDDMMYY, filterDateAsDDMMYY } from 'lib/utils';

import styles from './styles';

const SwipeableArea = ({
  classes,
  onClickIssuedToProduction,
  onClickUnknownLocation,
}) => (
  <div className={classes.swipeableArea}>
    <div className={classes.swipeRightArea}>Confirm Location</div>
    <div className={classes.swipeLeftArea}>
      <Button
        id="issuedToProduction"
        variant="contained"
        className={classNames(classes.backgroundButton, classes.buttonBlue)}
        onClick={onClickIssuedToProduction}
      >
        Issued to Production
      </Button>
      <Button
        id="unknownLocation"
        variant="contained"
        className={classNames(classes.backgroundButton, classes.buttonRed)}
        onClick={onClickUnknownLocation}
      >
        Unknown Location
      </Button>
    </div>
  </div>
);

SwipeableArea.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickIssuedToProduction: PropTypes.func.isRequired,
  onClickUnknownLocation: PropTypes.func.isRequired,
};

const StorageArea = ({
  onSetTopBarContent,
  onFetchStorageSlots,
  onResetStorageSlots,
  onSetCycleCount,
  onSetIssuedToProduction,
  onSetUnknownLocation,
  storageAreas,
  storageSlots,
  fetching,
  failed,
  classes,
  match,
  scrollToPreviousPosition,
  actionResult,
  sortState,
  setSortState,
}) => {
  // need this info to reset other swiped rows back to normal once another pallet is swiped
  const [palletBeingSwiped, setPalletBeingSwiped] = useState();
  const [palletForceReset, setPalletForceReset] = useState();

  const isLargerScreen = useMediaQuery(LARGER_THAN_PHONE);
  const history = useHistory();

  useEffect(() => {
    storageSlots.length && scrollToPreviousPosition();
  }, [storageSlots.length, scrollToPreviousPosition]);

  useEffect(() => {
    onFetchStorageSlots(match.params.id);
  }, [onFetchStorageSlots, match]);

  useEffect(() => {
    return () => {
      onResetStorageSlots();
    };
  }, [onResetStorageSlots]);
  useEffect(() => {
    const title = storageAreas.find(
      (area) => parseInt(area.id) === parseInt(match.params.id)
    );
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      text: title ? title.name : '',
      middleContent: QR_CODE_WITH_TEXT,
      rightContent: INVENTORY_MANAGER_ACTIONS,
    });
  }, [onSetTopBarContent, match, storageAreas]);

  const processPalletScan = (palletId) => {
    history.push(`/inventory-manager/pallets/${palletId}`);
  };

  if (failed) {
    return <div className={classes.loading}>No pallets found.</div>;
  }

  if (fetching || storageSlots.length === 0) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  }

  const formatReceivedDate = (palletReceivedDate) => {
    const receivedDate = new Date(palletReceivedDate);
    return filterDateAsDDMMYY(receivedDate);
  };

  const formatBrand = (brand) => (brand === null ? 'N/A' : brand);

  const changeSlotSort = () => {
    sortState === 'desc' ? setSortState('asc') : setSortState('desc');
  };

  if (storageSlots.length > 0) {
    return (
      <Fragment>
        <ListSubheader className={classes.header}>
          <ListItemText
            className={classes.storageAreaPositionHeader}
            disableTypography
            onClick={changeSlotSort}
          >
            Slot
            <IconButton className={classes.sortSlotIcon} size="large">
              {sortState === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
          </ListItemText>
          <ListItemText className={classes.storageAreaLot} disableTypography>
            Lot #
          </ListItemText>
          <ListItemText className={classes.storageAreaItem} disableTypography>
            Item
          </ListItemText>
          <ListItemText className={classes.storageAreaBrand} disableTypography>
            Brand
          </ListItemText>
          {isLargerScreen && (
            <>
              <ListItemText
                className={classes.storageAreaQuantityAndUOM}
                disableTypography
              >
                Qty + UOM
              </ListItemText>
              <ListItemText
                className={classes.storageAreaProdDateCode}
                disableTypography
              >
                Product Date Code
              </ListItemText>
              <ListItemText
                className={classes.storageAreaReceivedAt}
                disableTypography
              >
                Received At
              </ListItemText>
            </>
          )}
        </ListSubheader>
        <List
          data-testid="slots-list"
          className={classes.root}
          subheader={<li />}
        >
          {storageSlots.map((slot) => (
            <li key={`section-${slot.name}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader className={classes.subheader}>
                  {slot.name}
                </ListSubheader>
                {slot.pallets.map((pallet, index) => (
                  <ListItemLink
                    className={classNames(classes.listItemLink, {
                      [classes.checkedItem]: pallet.cycleCounted,
                    })}
                    style={{ transform: 'translate3d(0px, 0px, 0px)' }}
                    showArrow={false}
                    grey={index % 2 === 0}
                    white={index % 2 !== 0}
                    key={`pallet-${pallet.id}`}
                    link={`/inventory-manager/pallets/${pallet.id}`}
                    isSwipeable
                    swipeableProps={{
                      action: ({ swipeDirection, isSwiping }) => {
                        if (isSwiping) {
                          if (palletBeingSwiped !== pallet.id) {
                            setPalletForceReset(palletBeingSwiped);
                            setPalletBeingSwiped(pallet.id);
                          }
                        }

                        if (swipeDirection === 'right') {
                          onSetCycleCount(pallet.id, true);
                        }
                      },
                      leftSwipe: {
                        thresholdPx: '270',
                        resetPositionPx: '270',
                      },
                      rightSwipe: {
                        thresholdPx: '250',
                      },
                    }}
                    swipeableBackgroundComponent={
                      <SwipeableArea
                        classes={classes}
                        onClickIssuedToProduction={() =>
                          onSetIssuedToProduction(pallet.id)
                        }
                        onClickUnknownLocation={() =>
                          onSetUnknownLocation(pallet.id)
                        }
                      />
                    }
                    forceReset={palletForceReset === pallet.id}
                  >
                    <ListItemText
                      className={classes.storageAreaPosition}
                      disableTypography
                    >
                      {pallet.position}
                    </ListItemText>
                    <ListItemText
                      className={classes.storageAreaLot}
                      disableTypography
                    >
                      {pallet.id}
                    </ListItemText>
                    <ListItemText
                      className={classes.storageAreaItem}
                      disableTypography
                    >
                      {pallet.netsuiteOrderItemName}
                    </ListItemText>
                    <ListItemText
                      className={classes.storageAreaBrand}
                      disableTypography
                    >
                      {formatBrand(pallet.brand)}
                    </ListItemText>
                    {isLargerScreen && (
                      <>
                        <ListItemText
                          className={classes.storageAreaQuantityAndUOM}
                          disableTypography
                        >
                          {[pallet.quantity, pallet.unitOfMeasure].join(' ')}
                        </ListItemText>
                        <ListItemText
                          className={classes.storageAreaProdDateCode}
                          disableTypography
                        >
                          {[
                            formatDateAsDDMMYY(pallet.palletProductDate),
                            pallet.productDateCode,
                          ].join(': ')}
                        </ListItemText>
                        <ListItemText
                          className={classes.storageAreaReceivedAt}
                          disableTypography
                        >
                          {formatReceivedDate(pallet.receivedAt)}
                        </ListItemText>
                      </>
                    )}
                  </ListItemLink>
                ))}
              </ul>
            </li>
          ))}
        </List>
        <Snackbar
          loading={actionResult.fetching}
          failed={actionResult.result === 'error'}
          messaging={{
            loading: 'Saving...',
            failure: `Unable to save pallet ${actionResult.id}`,
            success: 'Saved!',
          }}
        />
        <Box
          id="hidden-scanner-input"
          sx={{ opacity: 0, height: 0, padding: 0 }}
        >
          <PalletScanTextField canScan onScan={processPalletScan} />
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className={classes.sorryContainer}>All Slots Empty</div>
      </Fragment>
    );
  }
};

const palletShape = PropTypes.shape({
  position: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  netsuiteOrderItemName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
});

StorageArea.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  onFetchStorageSlots: PropTypes.func.isRequired,
  onResetStorageSlots: PropTypes.func.isRequired,
  onSetIssuedToProduction: PropTypes.func.isRequired,
  onSetUnknownLocation: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  storageAreas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  storageSlots: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      pallets: PropTypes.arrayOf(palletShape).isRequired,
    })
  ),
  match: PropTypes.object.isRequired,
  scrollToPreviousPosition: PropTypes.func,
};

StorageArea.defaultProps = {
  storageSlots: [],
};

export default withStyles(styles)(StorageArea);
