import { createTheme } from '@mui/material/styles';

import { HC_GREEN, DARK_HC_GREEN, BLUE, ERROR_RED } from 'lib/constants';

export default createTheme({
  typography: { fontFamily: 'Muli' },
  palette: {
    primary: {
      main: HC_GREEN,
      dark: DARK_HC_GREEN,
    },
    secondary: {
      main: BLUE,
    },
    error: {
      main: ERROR_RED,
    },
    neutral: {
      main: '#64748B',
    },
  },
});
