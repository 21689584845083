import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { ACTIVITIES_WITH_IN_PROGRESS_STATE } from 'lib/constants';
import { monthSlashDay } from 'lib/utils';
import { Loading } from 'components/shared';
import { fetchActivityIngredientProgresses } from 'redux/productionProgress/rth/actions';
import { useActivityIngredients } from 'lib/custom_hooks';

import styles from './styles';
import MealsList from '../MealsList';

const useStyles = makeStyles(styles);

const ActivityIngredientProgresses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    activityIngredients,
    getMealIdsByIngredientId,
  } = useActivityIngredients();

  const { activity, ingredientId } = useParams();
  const {
    activityIngredientProgresses = {},
    activityIngredientProgressesFetching,
    activityIngredientProgressesError,
  } = useSelector((state) => state.rthProductionProgress);

  useEffect(() => {
    const mealIds = getMealIdsByIngredientId(ingredientId);

    if (!isEmpty(mealIds)) {
      dispatch(
        fetchActivityIngredientProgresses({ activity, ingredientId, mealIds })
      );
    }
  }, [activity, ingredientId, activityIngredients]);

  if (activityIngredientProgressesFetching) {
    return <Loading />;
  } else if (activityIngredientProgressesError) {
    return (
      <div className={classes.error}>Failed to fetch activity progresses.</div>
    );
  } else {
    return (
      <div data-testid="compound-ingredient-activity-progresses">
        {Object.entries(activityIngredientProgresses).map(
          ([date, progresses]) => {
            return (
              <div key={date} className={classes.container}>
                <div className={classes.text}>Week {monthSlashDay(date)}</div>
                <MealsList
                  activity={activity}
                  mealProgresses={progresses}
                  showInProgress={ACTIVITIES_WITH_IN_PROGRESS_STATE.includes(
                    activity
                  )}
                />
              </div>
            );
          }
        )}
      </div>
    );
  }
};

export default ActivityIngredientProgresses;
