import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { requestPodStatus } from 'redux/podStatus/actions';
import { TimeClockIcon, PodIcon, EyeIcon, WarningIcon } from 'components/icons';
import { POSITIVE_GREEN, NEGATIVE_RED, POD_GREY } from 'lib/constants';
import { minuteDiffFromNow } from 'lib/utils';

import styles from './styles';

const SUBMISSION_MINUTE_INTERVAL = 30;
const STALE_POD_MINUTE_INTERVAL = 180;

const Pod = ({ pod, classes, onRequestPodStatus }) => {
  const {
    lastProductionSubmittedAt,
    lastQaCheckSubmittedAt,
    needsStartOfLineCheck,
    highErrorRate,
  } = pod;

  const [podColor, setPodColor] = useState(POD_GREY);
  const [showQaTimeClock, setShowQaTimeClock] = useState(false);

  const setLastProductionSubmittedTimeout = (minutes) => {
    const minutesUntilNextSubmission = SUBMISSION_MINUTE_INTERVAL - minutes;
    const milliseconds = Math.abs(minutesUntilNextSubmission * 60000);

    setTimeout(() => setPodColor(NEGATIVE_RED), milliseconds);
  };

  const setLastQaSubmittedTimeout = (minutes) => {
    const minutesUntilNextSubmission = SUBMISSION_MINUTE_INTERVAL - minutes;
    const milliseconds = Math.abs(minutesUntilNextSubmission * 60000);

    setTimeout(() => setShowQaTimeClock(true), milliseconds);
  };

  const changePodColor = (minutes) => {
    if (minutes > STALE_POD_MINUTE_INTERVAL) {
      return setPodColor(POD_GREY);
    } else if (SUBMISSION_MINUTE_INTERVAL <= minutes) {
      return setPodColor(NEGATIVE_RED);
    } else {
      return setPodColor(POSITIVE_GREEN);
    }
  };

  const onlyRetailBaggingActivity = () => {
    if (pod.activities && pod.activities.length > 0) {
      return pod.activities.every((activity) => {
        return (
          activity.channel === 'Retail' && activity.type === 'meal_bagging'
        );
      });
    }
  };

  const PodContent = () => {
    return (
      <div className={classes.root}>
        <div className={classes.podTitle}>{pod.description}</div>
        <div className={classes.podWrapper}>
          <PodIcon className={classes.podSvg} podColor={podColor} />
          <TimeClockIcon
            className={classes.timeClockIcon}
            visible={!onlyRetailBaggingActivity() && showQaTimeClock}
          />
          <WarningIcon
            className={classes.warningIcon}
            visible={!onlyRetailBaggingActivity() && highErrorRate}
          />
          <EyeIcon
            className={classes.eyeIcon}
            visible={!onlyRetailBaggingActivity() && needsStartOfLineCheck}
          />
        </div>
      </div>
    );
  };

  const displayPod = () => {
    if (pod.lastProductionSubmittedAt) {
      return (
        <Link
          underline="none"
          color="inherit"
          component={RouterLink}
          data-testid="pod-link"
          to={{
            pathname: `/realtime_dashboard/pod/${pod.description
              .split(' ')
              .join('-')
              .toLowerCase()}`,
          }}
        >
          <PodContent />
        </Link>
      );
    } else {
      return <PodContent />;
    }
  };

  useEffect(() => {
    if (lastProductionSubmittedAt) {
      const minutesSinceLastQaSubmission = minuteDiffFromNow(
        lastQaCheckSubmittedAt
      );

      if (
        !lastQaCheckSubmittedAt ||
        minutesSinceLastQaSubmission >= SUBMISSION_MINUTE_INTERVAL
      ) {
        setShowQaTimeClock(true);
      } else {
        setShowQaTimeClock(false);
      }

      if (lastQaCheckSubmittedAt)
        setLastQaSubmittedTimeout(minutesSinceLastQaSubmission);
    }
  }, [lastProductionSubmittedAt, lastQaCheckSubmittedAt]);

  useEffect(() => {
    let timer = setInterval(() => {
      const minutesSinceLastSubmission = minuteDiffFromNow(
        lastProductionSubmittedAt
      );
      const facilityId = pod.packingFacilityId;
      changePodColor(minutesSinceLastSubmission);
      if (minutesSinceLastSubmission > STALE_POD_MINUTE_INTERVAL && pod.id) {
        changePodColor(minutesSinceLastSubmission);
        setShowQaTimeClock(false);
        onRequestPodStatus({ facilityId, podSlug: pod.slug });
      }
    }, 60000);
    return () => clearInterval(timer);
  }, [
    lastProductionSubmittedAt,
    onRequestPodStatus,
    pod,
    pod.packingFacilityId,
    pod.slug,
  ]);

  useEffect(() => {
    if (lastProductionSubmittedAt) {
      const minutesSinceLastSubmission = minuteDiffFromNow(
        lastProductionSubmittedAt
      );

      changePodColor(minutesSinceLastSubmission);
      setLastProductionSubmittedTimeout(minutesSinceLastSubmission);
    }
  }, [lastProductionSubmittedAt]);

  if (pod && !isEmpty(pod)) {
    return displayPod();
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  podStatuses: state.podStatuses,
});

const mapDispatchToProps = {
  onRequestPodStatus: requestPodStatus,
};

Pod.propTypes = {
  pod: PropTypes.shape({
    lastProductionSubmittedAt: PropTypes.instanceOf(Date),
    lastQaCheckSubmittedAt: PropTypes.instanceOf(Date),
    needsStartOfLineCheck: PropTypes.bool,
    description: PropTypes.string,
  }),
  classes: PropTypes.object,
};

Pod.defaultProps = {
  pod: {},
  classes: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Pod));
