import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Loading } from 'components/shared';
import { fetchProductionZoneItems } from 'redux/itemManager/actions';

import styles, { getMuiDataTableTheme } from './styles';
import {
  defaultTableConfiguration,
  options,
  AVAILABLE_COLUMNS,
  AVAILABLE_ACTIONS,
  DROPPED_OFF,
  CHECKED_IN,
  AUDIT_ACTION,
  MOVE_ACTION,
  RETURN_ACTION,
  PRINT_ACTION,
} from './dataTableConfig';

const useStyles = makeStyles(styles);

const ItemManagerTable = ({ columns, actions }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { locationId } = useParams();
  const timeZone = useSelector((state) => state.session?.timeZone);
  const selectedLocation = useSelector((state) =>
    state.podLocations?.pods.find((l) => l.id === Number(locationId))
  );
  const { productionZoneItems = [], loading, error } = useSelector(
    (state) => state.itemManager
  );

  const [
    includeEmptyProductionZoneItems,
    setIncludeEmtpyProductionZoneItems,
  ] = useState(false);

  useEffect(() => {
    locationId && dispatch(fetchProductionZoneItems({ locationId }));
  }, [locationId]);

  const onToggleDisplayZeroQuantity = () => {
    setIncludeEmtpyProductionZoneItems(!includeEmptyProductionZoneItems);
  };

  const withActions = productionZoneItems
    .filter(
      (productionZoneItem) =>
        includeEmptyProductionZoneItems || productionZoneItem.quantity > 0
    )
    .map((productionZoneItem) => ({
      ...productionZoneItem,
      timeZone,
      actions: {
        productionZoneItem,
        audit: {
          activated: actions.includes(AUDIT_ACTION),
        },
        move: {
          activated: actions.includes(MOVE_ACTION),
        },
        return: {
          activated: actions.includes(RETURN_ACTION),
        },
        print: {
          activated: actions.includes(PRINT_ACTION),
        },
      },
    }));

  const filteredColumns = defaultTableConfiguration.filter((column) => {
    return columns.includes(column.name);
  });

  const defaultSortBy = columns.includes(DROPPED_OFF)
    ? DROPPED_OFF
    : CHECKED_IN;

  return (
    <>
      {error && !loading && (
        <div className={classes.centeredLoading}>
          Failed to retrieve ingredient list. Please contact your system
          administrator.
        </div>
      )}
      {loading && (
        <div className={classes.centeredLoading}>
          <Loading />
        </div>
      )}
      {!loading && !error && (
        <>
          <FormGroup className={classes.filterZeroQuantityToggle}>
            <FormControlLabel
              control={
                <Switch
                  onClick={onToggleDisplayZeroQuantity}
                  color="default"
                  classes={{
                    switchBase: classes.toggleOn,
                    track: classes.toggleOn,
                  }}
                  checked={includeEmptyProductionZoneItems}
                />
              }
              label="Show QTY 0"
            />
          </FormGroup>
          <ThemeProvider theme={getMuiDataTableTheme()}>
            <MUIDataTable
              title={selectedLocation?.description}
              id={selectedLocation?.id}
              data={withActions}
              columns={filteredColumns}
              options={{
                ...options,
                sortOrder: {
                  name: defaultSortBy,
                  direction: 'desc',
                },
              }}
            />
          </ThemeProvider>
        </>
      )}
    </>
  );
};

ItemManagerTable.defaultProps = {
  actions: AVAILABLE_ACTIONS,
  columns: AVAILABLE_COLUMNS,
};

ItemManagerTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_ACTIONS)),
  columns: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_COLUMNS)),
};

export default ItemManagerTable;
