import { WHITE, GREY_650, MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  modalContainer: {
    backgroundColor: WHITE,
    width: '500px',
    margin: '10% auto',
    padding: '15px',
    fontFamily: 'Muli',
    overflow: 'auto',
    position: 'relative',
    borderRadius: '4px',
  },
  customFit: {
    width: 'fit-content',
    padding: 0,
  },
  primaryText: {
    fontWeight: 600,
    fontSize: '20px',
    marginBottom: '10px',
  },
  secondaryText: { color: GREY_650, marginBottom: '20px' },
  close: { position: 'absolute', top: '-10px', right: '0' },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    modalContainer: {
      height: '80%',
      margin: '10% 1%',
      maxWidth: '90%',
      position: 'absolute',
    },
  },
};
