import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ASSEMBLY, COOKING, MIXING, CHILLING } from 'lib/constants';

import AssemblyForm from '../SubmissionForms/AssemblyForm';
import CookingForm from '../SubmissionForms/CookingForm';
import MixingForm from '../SubmissionForms/MixingForm';
import ChillingForm from '../SubmissionForms/ChillingForm';
import styles from './styles';

const useStyles = makeStyles(styles);

const MealsList = ({ activity, mealProgresses, showInProgress }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProgress, setSelectedProgress] = useState({});

  useEffect(() => {
    const currentProgress = mealProgresses.find(
      (progress) => progress.id === selectedProgress.id
    );

    currentProgress && setSelectedProgress(currentProgress);
  }, [mealProgresses]);

  const onClickRow = (progress) => {
    setSelectedProgress(progress);
    setDrawerOpen(true);
  };

  const renderForm = () => {
    const sharedFormProps = {
      open: drawerOpen,
      toggleOpen: setDrawerOpen,
      selectedProgress,
    };

    switch (activity) {
      case ASSEMBLY:
        return <AssemblyForm {...sharedFormProps} />;
      case COOKING:
        return <CookingForm {...sharedFormProps} />;
      case MIXING:
        return <MixingForm {...sharedFormProps} />;
      case CHILLING:
        return <ChillingForm {...sharedFormProps} />;
    }
  };

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>ID</TableCell>
              <TableCell className={classes.header}>Meal Name</TableCell>
              <TableCell className={classes.header}># Required</TableCell>
              {showInProgress && (
                <TableCell className={classes.header}># In Progress</TableCell>
              )}
              <TableCell className={classes.header}># Completed</TableCell>
              <TableCell className={classes.header}># Remaining</TableCell>

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={classes.clickableRows}>
            {mealProgresses.map((progress) => (
              <TableRow
                key={progress.id}
                className={classes.zebraRow}
                onClick={() => onClickRow(progress)}
              >
                <TableCell
                  className={classes.mealLetter}
                  component="th"
                  scope="row"
                >
                  {progress.mealLetter}
                </TableCell>
                <TableCell className={classes.cell}>
                  {progress.mealTitle}
                </TableCell>
                <TableCell className={classes.cell}>
                  {progress.goalCount}
                </TableCell>
                {showInProgress && (
                  <TableCell className={classes.cell}>
                    {progress.openCount}
                  </TableCell>
                )}
                <TableCell className={classes.cell}>
                  {progress.completedCount}
                </TableCell>
                <TableCell className={classes.cell}>
                  {progress.goalCount - progress.completedCount}
                </TableCell>
                <TableCell className={classes.cellLast}>
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderForm()}
    </>
  );
};

MealsList.propTypes = {
  activity: PropTypes.oneOf([ASSEMBLY, COOKING, MIXING, CHILLING]),
  mealProgresses: PropTypes.array,
  showInProgress: PropTypes.bool,
};

MealsList.defaultProps = {
  mealProgresses: [],
  showInProgress: false,
};

export default MealsList;
