import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';

import { pluralize } from 'lib/utils';
import { ASSEMBLY } from 'lib/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

const CountInput = ({ onUpdate, sublot }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState('');
  const [newCount, setNewCount] = useState(sublot.count);

  const countText =
    sublot.activity === ASSEMBLY
      ? pluralize(sublot.count, 'meal')
      : pluralize(sublot.count, 'recipe');

  const handleUpdate = async () => {
    const response = await onUpdate({
      sublotId: sublot.id,
      count: newCount,
    });

    if (response.ok) {
      setEditing(false);
      setError('');
    } else {
      setError('Something went wrong.');
    }
  };

  if (editing) {
    return (
      <div className={classes.inputRow}>
        <TextField
          error={error != ''}
          helperText={error}
          inputProps={{
            className: classes.input,
            min: '0',
            step: '1',
            'data-testid': 'new-count-input',
          }}
          label="Recipes"
          onChange={(e) => {
            setNewCount(e.target.value);
          }}
          size="small"
          type="number"
          value={newCount}
        />
        <Button
          className={classes.saveButton}
          color="primary"
          disabled={newCount < 0}
          onClick={handleUpdate}
          variant="text"
        >
          Save
        </Button>
      </div>
    );
  } else {
    return (
      <div className={classes.count}>
        {countText}{' '}
        <EditIcon
          className={classes.editIcon}
          onClick={() => setEditing(true)}
        />
      </div>
    );
  }
};

CountInput.propTypes = {
  sublot: PropTypes.object.isRequired,
};

export default CountInput;
