import {
  GREY_100,
  GREY_150,
  GREY_600,
  GREY_800,
  ROBOTO,
  HC_GREEN,
  WHITE,
  LIGHT_GREEN,
} from 'lib/constants';

// Re-usable styles across this specific app
export const arrowIcon = {
  fontSize: 16,
};

export const cell = {
  color: GREY_800,
  fontFamily: ROBOTO,
  fontSize: '14px',
  fontWeight: '400',
  padding: '16px',
};

export const cellLast = {
  ...cell,
  textAlign: 'right',
};

export const header = {
  ...cell,
  fontSize: '12px',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  color: GREY_600,
};

export const zebraRow = {
  '&:nth-child(odd)': {
    background: `${GREY_150}`,
  },
  '&:last-child td, &:last-child th': { border: 0 },
};

export const clickableRows = {
  '&:hover': {
    cursor: 'pointer',
  },
};

export const formLabelText = {
  fontFamily: ROBOTO,
  fontWeight: 700,
  size: '16px',
  lineHeight: '24px',
  padding: '30px 0 10px 0',
};

export const formSubmitButton = {
  minWidth: '113px',
  backgroundColor: HC_GREEN,
  color: `${WHITE} !important`,
  fontFamily: ROBOTO,
  fontSize: 17,
  alignSelf: 'flex-end', // this will position if parent is flex
  float: 'right', // this will position if parent is not flex
  padding: '8px 22px',
  margin: '18px 24px',
  '&:disabled': {
    color: WHITE,
    backgroundColor: LIGHT_GREEN,
  },
};

export const formDivider = {
  width: '95%',
  height: `1px solid ${GREY_100}`,
};

export const sublotStyles = {
  sublotDetails: {
    padding: '20px',
  },
  sublotHeader: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '160%',
    marginBottom: '24px',
  },
  sublot: {
    margin: '12px 0',
  },
};
