import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';

export * from './date';
export * from './number';
export * from './string';
export * from './context';
export * from './packingFacilityHelpers';

export function isCurrentMenu(menus) {
  return (
    menus.selectedMenu.id &&
    menus.results &&
    menus.results[0].id == menus.selectedMenu.id
  );
}

export const hideNativeKeyboard = (ref) => {
  if (ref.current) {
    ref.current.setAttribute('readonly', 'readonly');
  }

  setTimeout(function () {
    if (ref.current) {
      ref.current.removeAttribute('readonly');
    }
  }, 1);
};

export const makeQueryParamURL = (originalURL, params = {}) => {
  const url = new URL(originalURL);

  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return url;
};

export const buildIngredientAssignmentQuery = (ingredientAssignmentIds) => {
  let iaString = '';
  ingredientAssignmentIds.forEach((ia) => {
    iaString += `ingredient_assignment_ids[]=${String(ia)}&`;
  });

  return iaString.slice(0, -1);
};

export const buildArrayUrlParameter = (name, values) => {
  let queryString = '';
  values.forEach((val) => {
    queryString += `${name}[]=${String(val)}&`;
  });
  return queryString.slice(0, -1);
};
export const snakeCasedParams = (params) =>
  mapKeys(params, (_value, key) => {
    return snakeCase(key);
  });
