import { MEDIA_QUERY_PHONE_SCREEN, MEDIA_QUERY_TABLET } from 'lib/constants';

let x;
let rainKeyFrames = {};
let rainStyles = {};

for (x = 0; x <= 100; x++) {
  const delay = Math.random() * 2 - 1;
  const duration = Math.random() + 0.5;
  const left = Math.floor(Math.random() * 100);
  const top = Math.floor(Math.random() * 100) + 50 * -1;
  rainKeyFrames[`@keyframes drop-${x}`] = {
    '90%': {
      opacity: '1',
    },
    '100%': {
      opacity: '0',
      transform: 'translateY(100vh)',
    },
  };

  rainStyles[`&:nth-of-type(${x})`] = {
    animationName: `$drop-${x}`,
    animationDelay: `${delay}s`,
    animationDuration: `${duration}s`,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    left: `${left}%`,
    top: `${top}px`,
    overflow: 'hidden',
  };
}

export default {
  ...rainKeyFrames,

  willie: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/winter_rabbit.png") no-repeat left bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '95px',
    left: '120px',
    zIndex: '-2',
    width: '185px',
    height: '250px',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  nelson: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/bunny.png") no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '160px',
    left: '30px',
    zIndex: '-2',
    width: '185px',
    height: '250px',
    WebkitTransform: 'scaleX(-1)',
    transform: 'scaleX(-1)',
  },
  rainbowReal: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/rainbow.png") no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    right: '10px',
    zIndex: '100',
    width: '90%',
    height: '90%',
    WebkitTransform: 'rotate(-61deg)',
    transform: 'rotate(-61deg)',
    pointerEvents: 'none',
  },
  rainCloud: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/rain-cloud.png") no-repeat right top',
    backgroundSize: 'contain',
    position: 'absolute',
    right: '-66px',
    top: '-32px',
    zIndex: '-2',
    width: '330px',
    height: '350px',
  },
  greenUmbrella: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/green-umbrella.png") no-repeat left bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: '-5',
    width: '334px',
    height: '334px',
    bottom: '163px',
    left: '16px',
  },
  rainHat: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/yellow-hat.png") no-repeat left bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: '0',
    width: '100px',
    height: '100px',
    bottom: '217px',
    left: '164px',
    transform: 'scaleX(-1)',
  },
  butterflies: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/butterflies.gif") no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: '0',
    width: '400px',
    height: '180px',
    bottom: '150px',
    right: '0',
  },
  butterflies2: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/butterflies.gif") no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: '0',
    width: '200px',
    height: '180px',
    bottom: '165px',
    right: '200px',
    transform: 'scaleX(-1)',
  },
  fallingRain: {
    height: '30px',
    position: 'absolute',
    zIndex: '-5',
    ...rainStyles,
  },

  [MEDIA_QUERY_TABLET]: {
    rainbowReal: {
      right: 0,
      left: 0,
      width: '1000px',
      height: '1000px',
      WebkitTransform: 'rotate(-71deg)',
      transform: 'rotate(-71deg)',
    },
    willie: { transform: 'translate(400px, -410px) scale(0.75)' },
    rainHat: { transform: 'translate(390px, -400px) scale(0.75)' },
    nelson: {
      left: '445px',
      bottom: '539px',
      transform: 'scale(0.75) scaleX(-1)',
    },
    greenUmbrella: { left: '382px', bottom: '533px', transform: 'scale(0.75)' },
    butterflies: { transform: 'scale(1.3)' },
    butterflies2: { transform: 'scale(1.5)' },
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    rainbowReal: {
      right: 0,
      left: '60px',
      bottom: '135px',
      width: '166%',
      height: '90%',
      WebkitTransform: 'rotate(-79deg)',
      transform: 'rotate(-79deg)',
    },
    willie: { display: 'none' },
    rainHat: { display: 'none' },
    nelson: { display: 'none' },
    greenUmbrella: { display: 'none' },
    butterflies: { bottom: '0', left: '5px' },
    butterflies2: { right: '0', bottom: '250px', transform: 'scale(.75)' },
  },
};
