import {
  GREY_400,
  AMBER_YELLOW,
  SUCCESS_GREEN,
  LIGHT_GREEN,
  TRANQUIL_BLUE,
  ERROR_RED,
} from 'lib/constants';

import { cell, header } from '../styles';

export default {
  cell,
  header,
  collapseContainer: { padding: 0, borderBottom: 0 },
  row: {
    cursor: 'pointer',
    height: '10px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '14%',
  },
  title: {
    ...header,
    fontSize: '15px',
    fontWeight: 'bolder',
    marginRight: '8px',
  },
  totalProgressContainer: {
    padding: '20px 50px',
  },
  progressBarContainer: {
    width: '58%',
  },
  ingredientDetailsContainer: {
    padding: '0px',
    backgroundColor: 'rgb(250,250,250)',
    paddingTop: '20px',
  },
  completedContainer: {
    width: '28%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  completedText: {
    color: GREY_400,
    fontSize: '15px',
  },
  toggleDetailsLink: {
    height: '40px',
    fontWeight: 'bolder',
  },
  progressStepsContainer: {
    marginBottom: '10px',
  },
  progressStepContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  progressStep: {
    width: '100%',
    marginLeft: '60px',
  },
  progressCountsContainer: {
    marginLeft: '78px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  progressCount: {
    width: '160px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressStepText: {
    marginLeft: '8px',
    marginBottom: '8px',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  progressStepIconContainer: {
    color: GREY_400,
    display: 'flex',
    alignItems: 'center',
  },
  progressStepIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    paddingRight: '5px',
  },
  greyStepIcon: {
    color: GREY_400,
  },
  redStepIcon: {
    color: ERROR_RED,
  },
  yellowStepIcon: {
    color: AMBER_YELLOW,
  },
  blueStepIcon: {
    color: TRANQUIL_BLUE,
  },
  lightGreenStepIcon: {
    color: LIGHT_GREEN,
  },
  darkGreenStepIcon: {
    color: SUCCESS_GREEN,
  },
  mixingStepContainer: {
    marginLeft: '84px',
  },
  statusAlignment: {
    marginTop: '8px',
  },
  divider: {
    margin: '10px auto 18px',
    width: '82%',
  },
};
