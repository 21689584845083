import React from 'react';
import times from 'lodash/times';
import makeStyles from '@mui/styles/makeStyles';

import RainSvg from './RainSvg';
import styles from './styles';

const useStyles = makeStyles(styles);

const Spring = () => {
  const classes = useStyles();

  return (
    <>
      {times(100, (i) => {
        return <RainSvg key={i} className={classes.fallingRain} />;
      })}
      <div className={classes.nelson} />
      <div className={classes.willie} />
      <div className={classes.rainbowReal} />
      <div className={classes.rainCloud} />
      <div className={classes.greenUmbrella} />
      <div className={classes.rainHat} />
      <div className={classes.butterflies} />
      <div className={classes.butterflies2} />
    </>
  );
};

export default Spring;
