import Rollbar from 'rollbar';
import * as Sentry from '@sentry/react';

import {
  REACT_APP_ROLLBAR_TOKEN,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
} from 'lib/constants';

/**
 *
 * @description A abstraction wrapper for Rollbar and Sentry to add custom default configurations and
 *              decouple it as a dependency
 * @example
 *  try{
 *    somefunction();
 * }
 *  catch( error ){
 *    logError('my message', error, function(){
 *      //do stuff
 *    });
 *  }
 *
 */

function rollbarLogger() {
  if (!REACT_APP_ROLLBAR_TOKEN) return null;

  try {
    return new Rollbar({
      accessToken: REACT_APP_ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: !(process.env.NODE_ENV === 'development'),
    });
  } catch (e) {
    console.warn(`WARNING: Rollbar initialization failed: ${e}`); // eslint-disable-line no-console
    return null;
  }
}

function initializeSentry() {
  if (!REACT_APP_SENTRY_DSN) return null;

  try {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      allowUrls: [
        /https?:\/\/((.*).herokuapp.com)/,
        /https?:\/\/localhost/,
        /https?:\/\/((.*).homechef.com)/,
      ],
      environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
      integrations: [
        Sentry.replayIntegration({
          maskAllInputs: true,
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.1,
      tracesSampleRate: REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
    });
    return true;
  } catch (e) {
    console.warn(`WARNING: Sentry initialization failed: ${e}`); // eslint-disable-line no-console
    return null;
  }
}

const rollbar = rollbarLogger();
const sentry = initializeSentry();

/**
 * Configures the Rollbar logger/adds Sentry context with default params that can be overridden
 *
 * @param {Object} properties Configuration Propery Object
 * @example payload = { person: { name: 'John'} }
 * @return null
 *
 * {@link https://docs.rollbar.com/docs/rollbarjs-configuration-reference}
 */
export const configureLogger = (properties = {}) => {
  if (rollbar) {
    rollbar.configure(properties);
  }

  if (sentry) {
    Sentry.setUser(properties.payload.person);
  }
};

/**
 *
 * Order does not matter
 * @typedef {Object} LogParams messaging and callbacks
 * @param {String} [message] The name of the message
 * @param {Exception} [err] The exception object to send
 * @param {Object} [custom] The custom payload data to send to Rollbar.
 * @param {Function} [callback] The function to call once the message has been sent to Rollbar.
 *
 */

/**
 * Logs messages at the info level.
 *
 * @param {String} [message]
 */
export const logInfo = function (message) {
  if (rollbar) {
    rollbar.info(message);
  }

  if (sentry) {
    Sentry.captureMessage(message, 'info');
  }
};
/**
 * Logs messages at the error level.
 *
 * @param {String} [message]
 */
export const logError = function (error, info = {}) {
  if (rollbar) {
    rollbar.error(error);
  }

  if (sentry) {
    Sentry.captureException(error, {
      contexts: { 'Provided Context': info },
    });
  }
};
/**
 * Logs messages at the warn level.
 *
 * @param {String} [message]
 */
export const logWarning = function (message) {
  if (rollbar) {
    rollbar.warning(message);
  }

  if (sentry) {
    Sentry.captureMessage(message, 'warning');
  }
};
/**
 * Logs messages at the debug level.
 *
 * @param {String} [message]
 */
export const logDebug = function (message) {
  if (rollbar) {
    rollbar.debug(message);
  }

  if (sentry) {
    Sentry.captureMessage(message, 'debug');
  }
};
/**
 * Logs messages at the critical level.
 *
 * @param {String} [message]
 */
export const logCritical = function (message) {
  if (rollbar) {
    rollbar.critical(message);
  }

  if (sentry) {
    Sentry.captureMessage(message, 'fatal');
  }
};

export default {
  configureLogger,
  logInfo,
  logError,
  logWarning,
  logDebug,
  logCritical,
};
