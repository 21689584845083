import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import { useActivityIngredients } from 'lib/custom_hooks';
import { updateIngredientProgress } from 'redux/productionProgress/rth/actions';

import FormDrawer from '../formComponents/FormDrawer';
import styles from './styles';
import SublotCard from '../../SublotCard';

const useStyles = makeStyles(styles);

const ChillingForm = ({ open, selectedProgress, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getMealIdsByIngredientId } = useActivityIngredients();

  const onUpdate = async (data) => {
    data.progressId = selectedProgress.id;
    data.mealIds = getMealIdsByIngredientId(selectedProgress.ingredientId);

    const response = await dispatch(updateIngredientProgress(data));

    return response;
  };

  const renderSublots = () => {
    const hasOpenSublots = !isEmpty(selectedProgress.openSublots);
    const hasCompletedSublots = !isEmpty(selectedProgress.completedSublots);

    return (
      <>
        {!hasOpenSublots && !hasCompletedSublots && (
          <>
            <hr className={classes.formDivider} />
            <div className={classes.sublotDetails}>
              <div className={classes.sublotHeader}>
                No Complete or In-Progress Sublots
              </div>
            </div>
          </>
        )}
        {hasOpenSublots && (
          <>
            <hr className={classes.formDivider} />
            <div className={classes.sublotDetails}>
              <div className={classes.sublotHeader}>
                Record Equipment and Complete In-Progress Sublots
              </div>
              {selectedProgress?.openSublots?.map((sublot) => {
                return (
                  <div className={classes.sublot} key={sublot.id}>
                    <SublotCard sublot={sublot} onUpdate={onUpdate} />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {!isEmpty(selectedProgress?.completedSublots) && (
          <>
            <hr className={classes.formDivider} />
            <div className={classes.sublotDetails}>
              <div className={classes.sublotHeader}>Completed Sublots</div>
              {selectedProgress.completedSublots.map((sublot) => {
                return (
                  <div className={classes.sublot} key={sublot.id}>
                    <SublotCard sublot={sublot} onUpdate={onUpdate} />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <FormDrawer open={open} toggleOpen={toggleOpen} drawerWidth="66%">
        <div className={classes.drawerContainer}>
          <div className={classes.drawerHeader}>
            <div className={classes.drawerTitle}>
              {selectedProgress.ingredientName} -{' '}
              {selectedProgress.ingredientId}
            </div>
            <div className={classes.drawerSubheader}>
              {selectedProgress.mealLetter} - {selectedProgress.mealTitle}
            </div>
          </div>
        </div>
        {renderSublots()}
      </FormDrawer>
    </>
  );
};

ChillingForm.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedProgress: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default ChillingForm;
